import Vue from 'vue'
import VueRouter from 'vue-router'
import lanhu_macbookpro1617 from '../views/lanhu_macbookpro1617/index.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'lanhu_macbookpro1617',
    component: lanhu_macbookpro1617
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
