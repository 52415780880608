<template>
  <div class="page flex-col" data-aos="fade-up" id="top">
    <div class="block_1 flex-col">
      <span
        class="text_1"
        data-aos="fade-right"
        data-aos-anchor-placement="top-center"
        data-aos-duration="1500"
        data-aos-delay="500"
        data-aos-once="false"
        id="vital1"
        >GAGE&nbsp;——
      </span>
      <div class="block_2 flex-col justify-center">
        <div class="box_1 flex-col">
          <div class="image-wrapper_1 flex-row">
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="./assets/img/FigmaDDSSlicePNG468a6d386bcbfcbfee96a200781dc9e1.png"
            />
          </div>
          <div class="group_1 flex-row justify-between">
            <div class="box_2 flex-col">
              <span
                class="text_2"
                data-aos="fade-right"
                data-aos-anchor-placement="top-center"
                data-aos-duration="1000"
                data-aos-delay="500"
                data-aos-once="false"
                >GAGE</span
              >
              <span
                class="text_3"
                data-aos="fade-right"
                data-aos-anchor-placement="top-center"
                data-aos-duration="1000"
                data-aos-delay="1000"
                data-aos-once="false"
                >The&nbsp;Future&nbsp;of&nbsp;Gold&nbsp;Intelligent&nbsp;<br />Trading</span
              >
              <div
                class="text-wrapper_1 flex-col"
                data-aos="fade-right"
                data-aos-anchor-placement="top-center"
                data-aos-duration="1000"
                data-aos-delay="1500"
                data-aos-once="false"
              >
                <span class="text_4">learn&nbsp;mor</span>
              </div>
              <img
                class="label_2"
                referrerpolicy="no-referrer"
                src="./assets/img/FigmaDDSSlicePNGfde2d2dd29ed1b829f5274735c2447dd.png"
              />
            </div>
            <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/FigmaDDSSlicePNG6af4c586417986c030562b0069247faf.png"
              data-aos="fade-left"
              data-aos-anchor-placement="top-center"
              data-aos-duration="1000"
              data-aos-delay="500"
              data-aos-once="false"
            />
          </div>
          <div class="group_2 flex-row">
            <span class="text_5">GAGE</span>
            <span class="text_6">Home</span>
            <span class="text_7" @click="clickToJumpTo('#vital1')">Edge</span>
            <span class="text_8" @click="clickToJumpTo('#limitation')">limitation</span>
            <span class="text_9" @click="clickToJumpTo('#Science')">Science</span>
            <img
              class="thumbnail_1"
              referrerpolicy="no-referrer"
              src="./assets/img/FigmaDDSSlicePNG770633c181158d584814bc1559a73cc1.png"
            />
            <div class="text-wrapper_2 flex-col" @click="clickToLogIn()">
              <span class="text_10">Login</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tailStyle image_2">
      <div class="middleTail">
        <div class="tailLeftSide">
          <div class="tailLogo">
            <img src="./assets/img/pic_logo@2x.png" alt="" />
          </div>
          <div class="tailIcontitle">
            <div class="tailText">Managed by Artificial Intelligence</div>
            <div class="tailIcon">
              <ul>
                <li>
                  <img src="./assets/img/ic_you@2x.png" alt="" />
                </li>
                <li>
                  <img src="./assets/img/ic_tw@2x.png" alt="" />
                </li>
                <li>
                  <img src="./assets/img/ic_g@2x.png" alt="" />
                </li>
                <li>
                  <img src="./assets/img/ic_aa@2x.png" alt="" />
                </li>
                <li>
                  <img src="./assets/img/ic_mao@2x.png" alt="" />
                </li>
                <li>
                  <img src="./assets/img/ic_feiji@2x.png" alt="" />
                </li>
              </ul>
            </div>
          </div>
          <div class="tailBackground">
            <img src="./assets/img/pic_chiuzi@2x.png" alt="" />
          </div>
        </div>

        <div class="rightContent">
          <div class="theFirstPiece">
            <div class="firstTitle" @click="clickToJumpTo('#top')">GAGE</div>
            <div class="theFirstPieceOfContent">
              <ul>
                <li @click="clickToJumpTo('#top')">Home</li>
                <li @click="clickToJumpTo('#vital1')">Edge</li>
                <li @click="clickToJumpTo('#limitation')">limitation</li>
                <li @click="clickToJumpTo('#Science')">Science</li>
                <li @click="clickToLogIn()">Login</li>
              </ul>
            </div>
          </div>
          <!-- <div class="theFirstPiece">
            <div class="firstTitle">GET CONNECTED</div>
            <div class="theFirstPieceOfContent">
              <ul>
                <li>Services</li>
                <li>Blog</li>
                <li>Newsletter</li>
              </ul>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="block_3 flex-col">
      <span
        class="text_11"
        data-aos="fade-right"
        data-aos-anchor-placement="top-center"
        data-aos-duration="1500"
        data-aos-delay="600"
        data-aos-once="false"
        >Your&nbsp;passport&nbsp;to&nbsp;enter&nbsp;the&nbsp;global&nbsp;gold&nbsp;market</span
      >
      <span
        class="text_12"
        data-aos="fade-right"
        data-aos-anchor-placement="top-center"
        data-aos-duration="1500"
        data-aos-delay="700"
        data-aos-once="false"
      >
        We&nbsp;lead&nbsp;the&nbsp;wave&nbsp;of&nbsp;intelligent&nbsp;trading,&nbsp;allowing&nbsp;you&nbsp;to&nbsp;have&nbsp;an&nbsp;excellent&nbsp;trading&nbsp;experience&nbsp;in&nbsp;every&nbsp;country's&nbsp;gold&nbsp;market.
      </span>
      <div
        class="text-wrapper_3 flex-col"
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        data-aos-duration="1000"
        data-aos-delay="1000"
        data-aos-once="false"
      >
        <span class="paragraph_1">
          Our&nbsp;powerful&nbsp;AI&nbsp;engine&nbsp;analyzes&nbsp;the&nbsp;gold&nbsp;market&nbsp;prices&nbsp;of&nbsp;each&nbsp;country&nbsp;at&nbsp;an&nbsp;astonishing&nbsp;speed
          <br />
          Not&nbsp;only&nbsp;can&nbsp;it&nbsp;help&nbsp;you&nbsp;predict&nbsp;trends,&nbsp;but&nbsp;it&nbsp;can&nbsp;also&nbsp;help&nbsp;you&nbsp;buy&nbsp;and&nbsp;sell&nbsp;at&nbsp;the&nbsp;best&nbsp;time
          <br />
          Stable&nbsp;income&nbsp;acquisition.&nbsp;No&nbsp;matter&nbsp;where&nbsp;you&nbsp;are,&nbsp;GAGE&nbsp;will&nbsp;provide&nbsp;timely&nbsp;support&nbsp;for&nbsp;your&nbsp;investments.
        </span>
      </div>
      <span
        class="text_13"
        data-aos="flip-left"
        data-aos-anchor-placement="top-center"
        data-aos-duration="1000"
        data-aos-delay="100"
        data-aos-once="false"
        id="limitation"
        >GAGE</span
      >
      <span
        class="text_14"
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        data-aos-duration="1000"
        data-aos-delay="200"
        data-aos-once="false"
        >Gold&nbsp;markets&nbsp;covering&nbsp;multiple&nbsp;countries&nbsp;worldwide</span
      >
      <span
        class="text_15"
        data-aos="fade-down"
        data-aos-anchor-placement="top-center"
        data-aos-duration="1000"
        data-aos-delay="300"
        data-aos-once="false"
      >
        Enable&nbsp;you&nbsp;to&nbsp;leverage&nbsp;market&nbsp;changes&nbsp;in&nbsp;different&nbsp;regions&nbsp;to&nbsp;maximize&nbsp;your&nbsp;investment&nbsp;return.&nbsp;Our&nbsp;platform&nbsp;enables&nbsp;you&nbsp;to&nbsp;seamlessly&nbsp;switch&nbsp;between&nbsp;multiple&nbsp;markets&nbsp;and&nbsp;seize&nbsp;diverse&nbsp;opportunities.
      </span>
      <div class="list_1 flex-row">
        <div
          class="image-text_1 flex-col justify-between"
          v-for="(item, index) in loopData0"
          :key="index"
          data-aos="flip-left"
          data-aos-anchor-placement="top-center"
          data-aos-duration="1500"
          :data-aos-delay="item.fanz"
          data-aos-once="false"
        >
          <img class="image_3" referrerpolicy="no-referrer" :src="item.lanhuimage0" />
          <span class="text-group_1" v-html="item.lanhutext0"></span>
        </div>
      </div>
      <div
        class="text-group_2 flex-col justify-between"
        data-aos="zoom-in-down"
        data-aos-anchor-placement="top-center"
        data-aos-duration="1500"
        data-aos-delay="2000"
        data-aos-once="false"
      >
        <span class="text_16" id="Science">GAGE</span>
        <span class="text_17"
          >Intelligent&nbsp;selection&nbsp;for&nbsp;gold&nbsp;trading</span
        >
      </div>
      <div class="list_2 flex-row">
        <div
          class="list-items_1 flex-col"
          v-for="(item, index) in loopData1"
          :key="index"
          data-aos="zoom-out-down"
          data-aos-anchor-placement="top-center"
          data-aos-duration="1500"
          :data-aos-delay="item.fanz"
          data-aos-once="false"
        >
          <div class="image-wrapper_2 flex-col">
            <img
              v-if="item.slot3 === 3"
              class="image_4"
              referrerpolicy="no-referrer"
              src="./assets/img/FigmaDDSSlicePNG55bbb313a6ebdff0550c6f53936bcad0.png"
            />
            <img
              v-if="item.slot4 === 4"
              class="image_5"
              referrerpolicy="no-referrer"
              :src="item.specialSlot4.lanhuimage0"
            />
          </div>
          <span
            v-if="item.slot2 === 2"
            class="text_18"
            v-html="item.specialSlot2.lanhutext0"
          ></span>
          <span v-if="item.slot1 === 1" class="text_19">
            Whether&nbsp;you&nbsp;are&nbsp;a&nbsp;beginner&nbsp;or&nbsp;a&nbsp;professional&nbsp;trader,&nbsp;GAGE&nbsp;will&nbsp;provide&nbsp;you&nbsp;with&nbsp;unique&nbsp;investment&nbsp;opportunities.
          </span>
        </div>
      </div>
      <span
        class="text_20"
        data-aos="flip-right"
        data-aos-anchor-placement="top-center"
        data-aos-duration="1500"
        data-aos-delay="0"
        data-aos-once="false"
        >GAGE</span
      >
      <div class="box_3 flex-col">
        <div class="section_1 flex-row">
          <div class="image-text_2 flex-row">
            <div class="text-group_3 flex-col justify-between">
              <span
                class="text_21"
                data-aos="zoom-out-right"
                data-aos-anchor-placement="top-center"
                data-aos-duration="2000"
                data-aos-delay="100"
                data-aos-once="false"
                >The&nbsp;Time&nbsp;Freedom&nbsp;of&nbsp;Gold&nbsp;Intelligent&nbsp;Trading</span
              >
              <span
                class="text_22"
                data-aos="zoom-out-right"
                data-aos-anchor-placement="top-center"
                data-aos-duration="2000"
                data-aos-delay="100"
                data-aos-once="false"
              >
                Our&nbsp;platform&nbsp;uniquely&nbsp;provides&nbsp;you&nbsp;with&nbsp;flexible&nbsp;timing&nbsp;options&nbsp;to&nbsp;suit&nbsp;different&nbsp;investment&nbsp;objectives&nbsp;and&nbsp;risk&nbsp;appetites.&nbsp;Whether&nbsp;you&nbsp;are&nbsp;a&nbsp;short-term&nbsp;trader&nbsp;or&nbsp;a&nbsp;long-term&nbsp;investor,&nbsp;GAGE&nbsp;will&nbsp;meet&nbsp;your&nbsp;needs.
              </span>
            </div>
            <img
              class="image_6"
              referrerpolicy="no-referrer"
              src="./assets/img/FigmaDDSSlicePNG3ae185c6476e2c6d234f222f26fccef4.png"
              data-aos="zoom-out-left"
              data-aos-anchor-placement="top-center"
              data-aos-duration="2000"
              data-aos-delay="100"
              data-aos-once="false"
            />
          </div>
        </div>
      </div>
      <img
        class="image_7"
        referrerpolicy="no-referrer"
        src="./assets/img/FigmaDDSSlicePNGf3cf603d3d7dd230cb96c82cde5da3cd.png"
        data-aos="fade-up-left"
        data-aos-anchor-placement="top-center"
        data-aos-duration="1500"
        data-aos-delay="0"
        data-aos-once="false"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showElement: true,
      loopData0: [
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/FigmaDDSSlicePNG89419f735b10733c9772ad1aec556b1a.png",
          lanhutext0:
            "We&nbsp;understand&nbsp;the&nbsp;uncertainty&nbsp;of&nbsp;financial&nbsp;markets",
          fanz: "100",
        },
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/FigmaDDSSlicePNG078ef2fd83bb0c78ae9b6c6be2a1857d.png",
          lanhutext0:
            "Our&nbsp;platform&nbsp;is&nbsp;equipped&nbsp;with&nbsp;powerful&nbsp;risk&nbsp;management&nbsp;tools",
          fanz: "500",
        },
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/FigmaDDSSlicePNG1c16cf73692472e622f84262f40d9c8c.png",
          lanhutext0:
            "Helping&nbsp;you&nbsp;minimize&nbsp;potential&nbsp;losses&nbsp;to&nbsp;the&nbsp;greatest&nbsp;extent&nbsp;possible",
          fanz: "1000",
        },
      ],
      loopData1: [
        {
          specialSlot4: {
            lanhuimage0:
              "https://lanhu.oss-cn-beijing.aliyuncs.com/FigmaDDSSlicePNGbf7ff43f6a0b43408c6efcb548dc751b.png",
          },
          slot4: 4,
          slot1: 1,
          fanz: "100",
        },
        {
          slot3: 3,
          specialSlot2: {
            lanhutext0:
              "Our&nbsp;platform&nbsp;makes&nbsp;gold&nbsp;trading&nbsp;simple&nbsp;and&nbsp;exciting,&nbsp;so&nbsp;you&nbsp;can&nbsp;trade&nbsp;with&nbsp;confidence",
          },
          slot2: 2,
          fanz: "500",
        },
        {
          specialSlot4: {
            lanhuimage0:
              "https://lanhu.oss-cn-beijing.aliyuncs.com/FigmaDDSSlicePNGd3d0ce32d9fe2d4b335dfa40f0e20025.png",
          },
          slot4: 4,
          fanz: "1000",
          specialSlot2: {
            lanhutext0:
              "Our&nbsp;AI&nbsp;intelligence&nbsp;will&nbsp;always&nbsp;be&nbsp;by&nbsp;your&nbsp;side,&nbsp;helping&nbsp;you&nbsp;achieve&nbsp;stable&nbsp;returns.",
          },
          slot2: 2,
        },
      ],
      constants: {},
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    clickToLogIn() {
      window.location.href = "https://gage-us.cc";
    },
    handleScroll() {
      const distanceToTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      console.log(distanceToTop + 400);
    },
    clickToJumpTo(anchorId) {
      document.querySelector(anchorId).scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />
