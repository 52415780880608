<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>
export default {
  name: 'App',
  created() {
    this.redirectBasedOnDevice();
  },
  methods: {
    redirectBasedOnDevice() {
      const isMobile = /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      if (isMobile) {
        // 如果是移动设备，跳转到移动端页面
        window.location.href = "https://m.gage-us.com";
      } else {
        // 如果是桌面设备，确保留在当前页面或跳转到桌面端页面
        // window.location.href = "http://www.example.com";
      }
    }
  }
}

</script>

<style lang="css" src="./assets/common.css">
</style>
